<template>
  <div class="intro">
    <h2>
      <transition name="left-fade-01">
        <span v-if="$parent.isLoad">stop <strong>scrolling,</strong></span>
      </transition>
      <transition name="left-fade-02">
        <span v-if="$parent.isLoad">start <strong>creating</strong></span>
      </transition>
    </h2>
    <div class="scrollDown">
      <transition name="center-fade">
        <div class="container" @click="$parent.scrollTo('#histoire')" v-if="$parent.isLoad">
          <div class="dot"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.intro {
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    max-width: 1260px;
    width: 100vw;
    padding: 30px 15px;
    margin: auto;
}

.intro h2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    font-size: 113px;
    font-weight: 400;
    line-height: .95;
    text-transform: lowercase;
    flex-grow: 1;
}
.intro h2 strong {}

.scrollDown {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 0;
    height: 0;
}

.scrollDown .container {
    height: 45px;
    width: 22.5px;
    border-radius: 22.5px;
    border: 2px solid #fff;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    cursor: pointer;
}

.scrollDown .container .dot {
    position: absolute;
    width: 11.25px;
    height: 11.25px;
    background-color: #fff;
    border-radius: 50%;
    margin-bottom: 5px;
    bottom: 0;
    -webkit-animation: scroll-down 3s infinite;
    animation: scroll-down 3s infinite;
}

@-webkit-keyframes scroll-down {
    0% {
        opacity: 0;
        bottom: 0;
    }

    5% {
        opacity: 1;
        bottom: 0;
    }

    50% {
        opacity: 1;
        bottom: 0;
    }

    85% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        bottom: 100%
    }
}

@keyframes scroll-down {
    0% {
        opacity: 0;
        bottom: 0;
    }

    5% {
        opacity: 1;
        bottom: 0;
    }

    50% {
        opacity: 1;
        bottom: 0;
    }

    85% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        bottom: 100%
    }
}

@media screen and (max-width: 1450px) and (min-width: 1024px) {
    .intro {
        max-width: none;
        width: 100vw;
        overflow-x: hidden;
        padding: 35px 100px 35px 80px;
    }
}

@media screen and (max-width: 1259px) and (min-width: 1024px){
  .intro h2 {
      font-size: 16vmin;
  }
}

@media screen and (max-width: 1023px) and (min-width: 480px) {
    .intro {
        max-width: none;
        overflow-x: hidden;
        padding: 35px 65px;
    }
    .intro h2 {
      font-size: 16vmin;
    }
}

@media screen and (max-width: 479px) {
    .intro {
        max-width: none;
        overflow-x: hidden;
        padding: 25px 20px;
    }
    .intro h2 {
      font-size: 16vmin;
    }
}
</style>