<template>
  <section class="histoire" id="histoire">
    <div>
      <div class="container">
        <div class="left">
          <img src="../assets/images/agency/visuel_equipe.png" alt="Notre histoire">
        </div>
        <div class="right">
          <h2 class="section-title" v-html="script[$parent.lang.toLowerCase()].title"></h2>
          <h3 class="subtitle"><strong v-html="script[$parent.lang.toLowerCase()].subtitle"></strong></h3>
          <p v-html="script[$parent.lang.toLowerCase()].text"></p>
          <ul>
            <li v-for="item in script[$parent.lang.toLowerCase()].keywords" :key="item">{{ item }}</li>
          </ul>
          <p v-html="script[$parent.lang.toLowerCase()].endSentence"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Histoire',
  data() {
    return {
      script: {
        fr: {
          title: `qui<br>sommes-<br><strong>nous ?</strong>`,
          subtitle: `L’agence créative et social media.`,
          text: `Notre starter créatif est de trouver la Big Idea qu’elle soit sociale, digitale ou physique.<br>
Notre valeur ajoutée est de créer les expériences les plus remarquables pour vous faire le plus remarquer !`,
          keywords: ["conseil", "création", "édito", "influence", "média", "production"],
          endSentence: `Fini de scroller, on commence quand&nbsp;?`
        },
        en: {
          title: `who<br>are<br><strong>we?</strong>`,
          subtitle: `The creative and social media agency`,
          text: `Our creative starter is to find the Big Idea whether it is social, digital or physical.<br>
Our added value is to create the most remarkable experiences to get you noticed the most!`,
          keywords: ["advice", "creation", "editorial", "influence", "media", "production"],
          endSentence: `Finished scrolling, when do we start?`
        },
      },
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.histoire {
  margin: 0 auto;
  padding-top: 180px;
  display: flex;
  flex-direction: column;
}

.histoire > div {
  position: relative;
}

.histoire .container {
  display: flex;
  flex-direction: row;
}

.histoire .left {
  width: 720px;
  font-size: 0;
  display: grid;
  place-items: center;
}

.histoire .left img {
  max-width: 100%;
  //max-height: 100%;
  //width: auto;
  height: auto;
}

.histoire .right {
  max-width: 480px;
  margin-left: 30px;
  z-index: 1;
  font-size: 22px;
}

.histoire p {
  margin-top: 1rem;
}

.histoire ul {
  margin: 1rem 0 0 0;
  list-style-type: none;
}

.histoire ul li {
  display: inline-block;
  border: solid 2px white;
  border-radius: 2rem;
  margin: 0.25rem;
  padding: 0.5rem 1rem;
}

.section-title {
  font-size: 96px;
  font-weight: 400;
}

.subtitle {
  font-size: 35px;
  padding: 1rem 0;
}

.section {
  min-height: calc(100vh - 130px);
  margin-top: 30px;
}
</style>

<style scoped>
@media screen and (max-width: 1330px) {
  .histoire .section-title {
    font-size: 73px;
  }
}

@media screen and (max-width: 1025px) {

  .histoire .section-title {
    font-size: 8vw;
    left: 0;
  }

  .histoire .container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .histoire .left {
    width: 100%;
    //-webkit-transform: scale(1.1) translate3d(-25px, 0, 0);
    //transform: scale(1.1) translate3d(-25px, 0, 0);
  }
}

@media screen and (max-width: 1023px) and (min-width: 480px) {
  .histoire .right {
    font-size: 28px;
  }

  .histoire .left {
    width: 100%;
    //transform: scale(1.1) translate3d(-75px, 0, 0);
  }

  .histoire .right {
    max-width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  .histoire .right p {
  }
}

@media screen and (max-width: 479px) {
  .histoire {
    padding-top: 100px;
  }

  .histoire .section-title {
    font-size: 12vw;
  }

  .histoire .right {
    font-size: 28px;
    margin-top: 90px;
    margin-left: 65px;
  }

  .histoire .right {
    max-width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
}
</style>
