<template>
  <div id="app" ref="parent">
    <Header :scrollPosition="scrollPosition" />
    <Nav />
    <Intro />
    <main class="main-container">
      <Histoire class="section" />
      <Clients class="section"/>
      <Contacts class="section"/>
    </main>
    <Footer />
  </div>
</template>

<script>
import Intro from './components/00-Intro.vue'
import Histoire from './components/01-Histoire.vue'
import Clients from './components/03-Clients.vue'
import Contacts from './components/04-Contacts.vue'

import Header from './components/10-Header.vue'
import Nav from './components/11-Nav.vue'
import Footer from './components/12-Footer.vue'

export default {
  name: 'App',
  components: {
    Intro,
    Histoire,
    Clients,
    Contacts,
    Header,
    Nav,
    Footer
  },
  methods: {
    scrollTo(element,position) {
      document.querySelector(element).scrollIntoView({behavior: "smooth", block: position ? position : "start"})
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
      if (this.scrollPosition > 999) {
        this.scrollPosition = 999
      }
    },
    currentLang(langSelect) {
      if (langSelect) {
        this.lang = 'EN'
        } else {
        this.lang = 'FR'
      }
      this.$cookie.set('lang', this.lang, {expires: 1, domain: '.hobbynote.com', path:'/'});
    }
  },
  created() {
    let lang = this.$cookie.get('lang')
    this.lang = lang == "FR" ? "FR" : lang == "EN" ? "EN" : "FR"
    this.$cookie.set('lang', this.lang, {expires: 1, domain: '.hobbynote.com', path:'/'});
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    window.addEventListener('load', this.updateScroll);
    window.addEventListener('load', ()=>{this.isLoad = true});
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  data() {
    return {
      scrollPosition: window.scrollY,
      isLoad: false,
      lang: 'FR'
    }
  }
}
</script>

<style>

@import './assets/fonts/fonts.css';
@import './assets/transition.css';

html, body, #app {
  background: rgba(33,41,44,1);
  background: linear-gradient(135deg, rgba(168,33,0,1) 0%, rgba(33,41,44,1) 20%, rgba(33,41,44,1) 80%, rgba(168,33,0,1) 100%);
  color: #fff;
  margin: 0;
  padding: 0;
}

html body ::-webkit-scrollbar {
  display: none !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#app {
  font-family: 'Kamerik', 'Arial', sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

html body ::-moz-selection {
  background: #fcd846;
}

html body ::selection {
  background: #fcd846;
}

.main-container {
  max-width: 1260px;
  padding: 30px 15px;
  margin: auto;
  counter-reset: section;
}

section a::-moz-selection, .section a::-moz-selection {
  color: #fcd846;
}

section a::selection, .section a::selection {
  color: #fcd846;
}

strong {
  //color: #00edda;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  padding: 0;
  margin: 0;
}

.section, section {
  margin-bottom: 100px;
  counter-increment: section;
  position: relative;
}

.section-title {
  line-height: 1;
  position: relative;
  text-transform: lowercase;
  font-size: 96px;
  font-weight: 400;
}

.subsection-title {
  line-height: 1;
  position: relative;
  font-size: 9vmin;
  font-weight: bold;
  text-transform: lowercase;
}

a[name="Facebook"]:hover { color: #4468b7 }
a[name="Instagram"]:hover { color: #df3569 }
a[name="Tiktok"]:hover { color: #141414 }
a[name="Twitter"]:hover { color: #0183c1 }
a[name="Youtube"]:hover { color: #b00 }
a[name="Linkedin"]:hover { color: #007bb6 }

a[name="Facebook"] span.icon::before { content: '\e006' }
a[name="Instagram"] span.icon::before { content: '\e007' }
a[name="Tiktok"] span.icon::before { content: '\e900' }
a[name="Twitter"] span.icon::before { content: '\e00c' }
a[name="Youtube"] span.icon::before { content: '\e00d' }
a[name="Linkedin"] span.icon::before { content: '\e008' }

a.link {
  position: relative;
  -webkit-transition: color 500ms 300ms ease;
  transition: color 500ms 300ms ease;
  background: transparent;
  color: inherit;
  text-decoration: none;
}

a.link:hover:after {
  left: 0;
  right: 0;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

a.link:hover {
  color: #fcd846;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
}

a.link::after {
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  top: 100%;
  height: 1px;
  background-color: #fcd846;
  -webkit-transition: all 500ms 100ms ease;
  transition: all 500ms 100ms ease;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
</style>
<style>
@media screen and (max-width: 1330px) and (min-width: 1024px) {
  .section-title { font-size: 125px;}
}
@media screen and (max-width: 1023px) and (min-width: 480px){
  .section-title { font-size: 90px;}
  section::before {
    font-size: 500px;
    letter-spacing: -10px;
    right: auto;
    top: 30px;
    left: -300px;
  }
}
@media screen and (max-width: 479px) {
  .section-title { font-size: 60px;}
  section::before {
    font-size: 300px;
    letter-spacing: -6px;
    right: auto;
    top: 100px;
    left: -50px;;
  }
  section p, section span, section ul {
    font-size: 18px;
  }
}

@media screen and (max-width: 1450px) and (min-width: 1024px) {
  .main-container {
    max-width: none;
    width: 100vw;
    overflow-x: hidden;
    padding: 35px 100px 35px 80px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 480px) {
  .main-container {
    max-width: none;
    width: 100vw;
    overflow-x: hidden;
    padding: 35px 65px;
  }
}

@media screen and (max-width: 479px) {
  .main-container {
    max-width: none;
    width: 100vw;
    overflow-x: hidden;
    padding: 25px 20px;
  }
}

//ONLY FOR DEBUGGING PURPOSE
 * {
  //outline: 1px solid red;
}
</style>
