<template>
  <section class="clients" id="clients">
    <h2 class="section-title" v-html="script[$parent.lang.toLowerCase()].title"></h2>
    <div class="clientsContainer">
      <div class="clientsContent">
        <div class="column" v-for="(clientArray, index) in clients" :key="index" :index="index">
          <div class="clientItem" v-for="(client, index) in clientArray" :key="index" :title="client.client"
               :style="{backgroundImage: 'url('+client.image+')'}">
            <!-- <div class="clientItem" v-for="(client, index) in clientArray" :key="index" :title="client.client" :style="{backgroundImage: 'url('+client.image+')'}" @click="goTo(client.site)"> -->
          </div>
        </div>
      </div>
    </div>
    <div class="clientNav">
      <div class="arrow left" @click="slide(-1)">
      </div>
      <div class="arrow right" @click="slide(1)">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Clients',
  mounted() {
    this.slide()
    let slider = document.querySelector('.clientsContent')
    slider.addEventListener('touchstart', e => {
      this.touchstartX = e.changedTouches[0].screenX;
    });
    slider.addEventListener('touchend', e => {
      this.touchendX = e.changedTouches[0].screenX;
      this.swip()
    });
  },
  methods: {
    slide(val) {
      if (window.innerWidth <= 1200) {
        const slider = document.querySelector('.clientsContainer')
        const columns = document.querySelectorAll('.column')
        if (this.sliderClick) {
          this.sliderClick = false
          if (val > 0) {
            this.sliderIndex = this.modulo(this.sliderIndex + 1, 5)
            slider.scrollTo({left: 0})
          }
          if (val < 0) {
            this.sliderIndex = this.modulo(this.sliderIndex - 1, 5)
            slider.scrollTo({left: columns[0].clientWidth * 2})
          }
          this.infinit(columns, this.sliderIndex);
          this.sliderClick = true
        }
        slider.scrollTo({left: columns[0].clientWidth, behavior: 'smooth'})
      }
    },
    infinit(columns, index) {
      let visibility = 3
      if (window.innerWidth < 769) {
        visibility = 2
      }
      if (window.innerWidth < 481) {
        visibility = 1
      }
      let prev = [this.modulo(index - 1, 5)]

      let current = []
      for (let i = 0; i < visibility; i++) {
        current.push(this.modulo(index + i, 5))
      }

      let next = [this.modulo(index + visibility, 5)]

      let positions = prev.concat(current).concat(next)

      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        column.style.order = 8
        for (let j = 0; j < positions.length; j++) {
          const position = positions[j];
          if (column.getAttribute('index') == position) {
            column.style.order = j
          }
        }
      }
    },
    goTo(link) {
      window.open(link, '_blank')
    },
    modulo(n, m) {
      return ((n % m) + m) % m;
    },
    swip() {
      if (this.touchendX < this.touchstartX) this.slide(1);
      if (this.touchendX > this.touchstartX) this.slide(-1);
    }
  },
  destroy() {
    let slider = document.querySelector('.clientsContent')
    slider.removeEventListener('touchstart', e => {
      this.touchstartX = e.changedTouches[0].screenX;
    });
    slider.removeEventListener('touchend', e => {
      this.touchendX = e.changedTouches[0].screenX;
      this.swip
    });
  },
  data() {
    return {
      script: {
        fr: {
          title: `quelques<br><strong>clients</strong>`
        },
        en: {
          title: `some<br><strong>clients</strong>`
        }
      },
      sliderIndex: 2,
      sliderClick: true,
      touchstartX: 0,
      touchendX: 0,
      clients: [
        [
          {
            image: require('@/assets/images/clients/ubisoft.svg'),
            client: "Ubisoft",
            site: "https://www.ubisoft.com/"
          },
          {
            image: require('@/assets/images/clients/dulux.svg'),
            client: "Dulux Valentine",
            site: "https://www.duluxvalentine.com/"
          },
          {
            image: require('@/assets/images/clients/thalassa.svg'),
            client: "Thalassa Sea & Spa",
            site: "https://www.thalassa.com/"
          }
        ],
        [
          {
            image: require('@/assets/images/clients/conforama.svg'),
            client: "Conforama",
            site: "https://www.conforama.fr/"
          },
          {
            image: require('@/assets/images/clients/thepeople.svg'),
            client: "The People",
            site: "https://www.thepeoplehostel.com/"
          },
          {
            image: require('@/assets/images/clients/24h.svg'),
            client: "24h Le Mans",
            site: "https://www.24h-lemans.com/"
          }
        ],
        [
          {
            image: require('@/assets/images/clients/atmosphera.svg'),
            client: "atmosphera",
            site: "https://www.atmosphera.com/"
          },
          {
            image: require('@/assets/images/clients/nomade.svg'),
            client: "Nomade aventure",
            site: "https://www.nomade-aventure.com/"
          },
          {
            image: require('@/assets/images/clients/lolaliza.svg'),
            client: "Lolaliza",
            site: "https://www.lolaliza.com/"
          }
        ],
        [
          {
            image: require('@/assets/images/clients/louvrelens.svg'),
            client: "Louvre Lens",
            site: "https://www.louvrelens.fr/"
          },
          {
            image: require('@/assets/images/clients/blackstore.svg'),
            client: "Black Store",
            site: "https://www.blackstore.fr/"
          },
          {
            image: require('@/assets/images/clients/nhood.svg'),
            client: "Nhood",
            site: "https://www.nhood.fr/"
          }
        ],
        [
          {
            image: require('@/assets/images/clients/grohe.svg'),
            client: "Grohe",
            site: "https://www.grohe.fr/"
          },
          {
            image: require('@/assets/images/clients/cdiscount.svg'),
            client: "Cdiscount",
            site: "https://www.cdiscount.com/"
          },
          {
            image: require('@/assets/images/clients/sikkens.svg'),
            client: "Sikkens",
            site: "https://www.sikkens.fr/"
          }
        ]
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clients {
  min-height: initial !important;
  padding-top: 180px;
}

.clients::before {
  top: 0;
}

.clientsContainer {
  position: relative;
  overflow-x: hidden;
  width: calc(100% + 30px);
  z-index: 1;
  margin-left: -15px;
  margin-top: 3rem;
}

.clientsContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.clientsContent .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.clientItem {
  width: calc((100vw - 290px) / 5);
  height: 100px;
  margin: 15px;
  background-size: 70% auto;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 250ms ease-In-Out;
  transition: all 250ms ease-In-Out;
  cursor: pointer;
}

.clientItem:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.clientNav {
  display: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.clientNav .arrow {
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 150ms ease-In-Out;
  transition: all 150ms ease-In-Out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.clientNav .arrow.left {
  margin-right: 5px;
}

.clientNav .arrow:hover {
  background-color: #131313;
}

.clientNav .arrow.left::before {
  content: '\e002';
  font-family: icons;
  width: 100%;
}

.clientNav .arrow.right::before {
  content: '\e003';
  font-family: icons;
  width: 100%;
}

.hidden {
  opacity: 0;
}
</style>
<style scoped>
@media screen and (min-width: 1451px) {
  .clientItem {
    width: calc((1260px - 150px) / 5);
  }
}

@media screen and (max-width: 1200px) {
  .clientItem {
    width: calc((100vw - 215px) / 3);
  }

  .clientNav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (max-width: 1023px) {
  .clientItem {
    width: calc((100vw - 165px) / 3);
  }
}

@media screen and (max-width: 769px) {
  .clientItem {
    width: calc((100vw - 160px) / 2);
  }
}

@media screen and (max-width: 481px) {
  .clientsContainer {
    margin-top: 1rem;
  }
  .clients {
    padding-top: 100px;
  }

  .clientItem {
    width: calc(100vw - 140px);
    margin: 15px 65px;
    background-size: 65%;
  }
}
</style>
