<template>
  <footer>
    <div class="syneido">
      <a href="https://www.syneido.com/" target="_blank">
        <img :src="require(`../assets/images/logo_syneido_${$parent.lang}.svg`)" alt="Syneido">
      </a>
    </div>
    <a href="https://www.facebook.com/business/partner-directory/details?id=474662073305303" target="_blank"
       class="fbp">
      <img src="../assets/images/agence-meta-for-business.png" height="50" alt="Marketink Partener">
    </a>
    <div class="mention">
      © hobbynote {{ currentYear }} | <a @click="showMention = !showMention"
                                         class="link">{{ $parent.lang == 'FR' ? 'Mentions légales' : 'Legal Notice' }}</a>
    </div>
    <div class="popin" :class="{active: showMention}">
      <div class="popin-overlay" @click="showMention = !showMention"></div>
      <div class="popin-box">
        <div class="popin-close" @click="showMention = !showMention">
          <span></span>
          <span></span>
        </div>
        <div class="popin-content">
          <div class="popin-title">Mentions légales</div>
          <div class="popin-text" v-if="$parent.lang == 'FR'">
            <p>
              En visitant et en utilisant les sites <a href="/" class="link">hobbynote.com</a> et <a
                href="https://jobs.hobbynote.com/" class="link">jobs.hobbynote.com</a> , vous
              marquez votre accord avec les présentes mentions légales. Celles-ci peuvent être modifiées à tout moment
              par le responsable de la publication de ce site. Le consommateur dispose de la faculté de sauvegarder ou
              d’éditer les présentes conditions générales, étant précisé que tant la sauvegarde que l’édition de ce
              document relèvent de sa seule responsabilité, ces conditions générales de vente pouvant être susceptible
              de subir des modifications.
            </p>
            <h3>Editeur du site / Responsable de publication</h3>
            <p>
              <strong>HOBBYNOTE SASU</strong><br>
              Les sites <a href="/" class="link">hobbynote.com</a> et <a href="https://jobs.hobbynote.com/"
                                                                         class="link">jobs.hobbynote.com</a> sont édités
              par la société Hobbynote au capital de 80 040 euros,
              dont le siège social est situé au 134 rue Royale, 59800 LILLE, et inscrite au Registre du Commerce
              et des Sociétés de Lille sous le numéro de SIRET 527 874 655 00030. (N° TVA : FR37527874655).
            </p>
            <h3>Hébergeur du site :</h3>
            <p>
              <strong>OVH</strong><br>
              2 rue KELLERMANN <br>
              59100 ROUBAIX, FRANCE
            </p>
            <h3>Conception Graphique</h3>
            <p>
              L’ensemble des éléments figurant sur le site demeure la propriété exclusive de la société Hobbynote et
              sont protégés par le droit de la propriété intellectuelle. Vous n’êtes pas autorisés à reproduire, quel
              qu’en soit le moyen ou le support, ces éléments sans le consentement du directeur de publication du
              site.
            </p>
            <h3>Loi informatiques et libertés</h3>
            <p>
              Conformément aux dispositions de la loi Informatique et Libertés du 6 janvier 1978, vous disposez d’un
              droit d’accès, de modification, de rectification et de suppression des données qui vous concernent (art.
              34 de la loi « Informatique et Libertés »). Pour l’exercer, adressez-vous à :
            </p>
            <p>
              <strong>HOBBYNOTE SASU</strong><br>
              134 rue Royale <br>
              59800 LILLE, FRANCE
            </p>
          </div>
          <div class="popin-text" v-if="$parent.lang == 'EN'">
            <p>
              By visiting and using the sites <a href="/" class="link">hobbynote.com</a> and <a
                href="https://jobs.hobbynote.com/" class="link">jobs.hobbynote.com</a>,
              you agree with the present legal notice. These can be modified at any time by the person responsible
              for the publication of this site. The consumer has the option of saving or editing these terms and
              conditions, it being specified that both the saving and editing of this document are the sole
              responsibility
              of the consumer, as these terms and conditions may be subject to change.
            </p>
            <h3>Site editor / Publication manager</h3>
            <p>
              <strong>HOBBYNOTE SAS</strong><br>
              The <a href="/" class="link">hobbynote.com</a> website and the <a href="https://jobs.hobbynote.com/"
                                                                                class="link">jobs.hobbynote.com</a>
              website are published by the company Hobbynote with a capital of 80 040 euros, whose head office is
              located at 7 bis rue Macquart, 59000 LILLE, and registered with the Lille Trade and Companies Register
              under the SIRET number 527 874 655 00030. (VAT NUMBER : FR37527874655).
            </p>
            <h3>Site host :</h3>
            <p>
              <strong>OVH</strong><br>
              2 rue KELLERMANN <br>
              59100 ROUBAIX, FRANCE
            </p>
            <h3>Graphic design</h3>
            <p>
              All the elements appearing on the site remain the exclusive property of the Hobbynote company and are
              protected by the intellectual property law. You are not authorized to reproduce, by any means or on any
              medium, these elements without the consent of the site's publishing director.
            </p>
            <h3>Data Protection Act</h3>
            <p>
              In accordance with the provisions of the French Data Protection Act of January 6, 1978, you have the right
              to access, modify, rectify and delete data concerning you (art. 34 of the French Data Protection Act). To
              exercise this right, please contact :
            </p>
            <p>
              <strong>HOBBYNOTE SAS</strong><br>
              134 rue Royale<br>
              59800 LILLE, FRANCE
            </p>
          </div>
        </div>

      </div>
    </div>
    <cookie-law>
      <div slot-scope="props">
        <p v-if="$parent.lang == 'FR'">En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de
          cookies destinés à vous proposer le meilleur service personnalisé. &nbsp;&nbsp;<a
              @click="showMention = !showMention" class="link">En savoir plus</a></p>
        <p v-if="$parent.lang == 'EN'">By continuing your navigation on this site, you accept the use of cookies
          intended to offer you the best personalized service. &nbsp;&nbsp;<a @click="showMention = !showMention"
                                                                              class="link">More information</a></p>
        <div class="buttons" v-if="$parent.lang == 'FR'">
          <button class="skew refuse" @click="props.close"><span>Refuser</span></button>
          <button class="skew accept" @click="props.accept"><span>Accepter</span></button>
        </div>
        <div class="buttons" v-if="$parent.lang == 'EN'">
          <button class="skew refuse" @click="props.close"><span>Decline</span></button>
          <button class="skew accept" @click="props.accept"><span>Accept</span></button>
        </div>
      </div>
    </cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'Footer',
  components: {CookieLaw},
  methods: {
    handleBodyBottom() {
      document.querySelector('body').style.paddingBottom = "0px"
    }
  },
  updated() {
    if (this.showMention) {
      document.querySelector('body').style.overflow = "hidden";
    }
    if (!this.showMention) {
      document.querySelector('body').style.overflow = "initial";
    }
  },
  data() {
    return {
      currentYear: null,
      showMention: false,
    }
  },
  mounted() {
    // Récupérer la valeur de la clé "cookie:accepted" depuis localStorage
    const acceptedCookie = localStorage.getItem("cookie:accepted");
    console.log(acceptedCookie)

    // Vérifier si le cookie existe et supprimer le padding bottom
    if (acceptedCookie) {
      document.querySelector('body').style.paddingBottom = "0px"
    } else {
      document.querySelector('body').style.paddingBottom = "70px"
    }

    // Get the current year
    this.currentYear = new Date().getFullYear();

    // Sélectionner tous les boutons dans la classe ".buttons"
    const buttons = document.querySelectorAll(".buttons button");

    // Ajouter un écouteur d'événement "click" à chaque bouton
    buttons.forEach(button => {
      button.addEventListener("click", this.handleBodyBottom);
    })

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  padding-bottom: 70px;  /* 70px is the height of the footer */
}

footer {
  width: 100%;
  background: rgba(41, 42, 43, 1);
  background: linear-gradient(135deg, rgba(41, 42, 43, 1) 0%, rgba(93, 43, 33, 1) 100%);
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

footer .syneido {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 15px;
  max-width: 1260px;
  margin: 0 auto;
}

footer .syneido img {
  height: 20px;
}

@media screen and (max-width: 1450px) and (min-width: 1024px) {
  footer .syneido {
    padding-left: 80px;
    max-width: 100vw;
  }
}

@media screen and (max-width: 1023px) and (min-width: 480px) {
  footer .syneido {
    padding-left: 65px;
  }
}

@media screen and (max-width: 860px) and (min-width: 701px) {
  footer .syneido img {
    height: 15px;
  }
}

@media screen and (max-width: 700px) {
  footer .syneido {
    position: relative;
    padding: 0;
    margin: 5px 0 0 0;
  }

  footer .syneido img {
    height: 15px;
  }
}

footer > a {
  height: 50px;
  margin-top: 8px;
}

footer .fbp {
  position: relative;
  z-index: 1;
  height: auto;
  text-align: center;
  text-decoration: none;
}

.mention {
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  margin: 16px 0 8px 0;
  text-align: center;
}

.popin {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: none;
}

.popin .popin-overlay {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.popin.active {
  display: block;
}

.popin.active .popin-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -150%, 0) scale(0.85);
  transform: translate3d(-50%, -150%, 0) scale(0.85);
  opacity: 0;
  width: calc(100% - 120px);
  max-width: 1200px;
  max-height: calc(100vh - 120px);
  padding: 50px;
  background-color: #fff;
  color: #000;
  -webkit-transition: all 250ms ease-In-Out;
  transition: all 250ms ease-In-Out;
  text-align: center;
  overflow-y: auto;
}

.popin.active .popin-box {
  -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
  transform: translate3d(-50%, -50%, 0) scale(1);
  opacity: 1;
}

.popin.active .popin-box .popin-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  cursor: pointer;
}

.popin.active .popin-box .popin-close span {
  display: block;
  position: absolute;
  top: 12.5px;
  left: 0;
  width: 30px;
  height: 5px;
  border-radius: 5px;
  background-color: #000;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: all 150ms ease-In-Out;
  transition: all 150ms ease-In-Out;
}

.popin.active .popin-box .popin-close span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.popin.active .popin-box .popin-close span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.popin-title {
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
  color: #fcd846;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.popin-text {
  text-align: left;
}

.popin-text h3 {
  margin-bottom: 10px;
}

.popin-text p {
  margin-bottom: 20px;
}

.popin-text a {
  color: #fcd846;
  font-weight: bold;
  position: relative;
  -webkit-transition: color 500ms 300ms ease;
  transition: color 500ms 300ms ease;
}

.Cookie {
  box-shadow: 0 0 20px 5px rgb(0 0 0 / 75%);
  padding: 0;
  z-index: 999999;
}

.Cookie > * {
  margin: 0
}

.Cookie p .link {
  display: inline-block;
  margin: 0;
}

.Cookie > div {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

.Cookie p {
  padding: 1.250em;
}

.Cookie .buttons {
  position: relative;
  display: flex;
  flex-direction: row;
  top: 0;
  bottom: 0;
}

.Cookie .skew {
  display: inline-block;
  padding: 20px;
  border: none;
  font-size: 20px;
  height: 100%;
  font-weight: 600;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.Cookie .skew.refuse {
  background-color: #dddddd;
  color: #444444;
}

.Cookie .skew.accept {
  background-color: #fcd846;
  color: #fff;
}

.Cookie a {
  color: #fcd846;
  text-decoration: none;
  margin-left: 10px;
  transition: color 500ms 300ms ease;
}

.Cookie .skew:hover::after {
  width: 200%;
  height: 0;
  padding-bottom: 200%;
  transition: all 150ms ease-in;
}

.Cookie .skew::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.15);
  transition: all 250ms 50ms ease-out;
}

</style>
<style scoped>
@media screen and (max-width: 880px) {
  .Cookie p {
    padding: 1em;
    font-size: 16px;
  }

  .Cookie .skew {
    font-size: 18px;
  }
}

@media screen and (max-width: 750px) {
  .Cookie p {
    padding: 1em;
    font-size: 12px;
  }

  .Cookie .skew {
    font-size: 14px;
  }
}

@media screen and (max-width: 479px) {

  footer .mention, footer .mention a {
    font-size: 14px;
  }
}
</style>
