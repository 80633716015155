<template>
  <section class="contacts" id="contacts">
    <h2 class="section-title" v-html="script[$parent.lang.toLowerCase()].title"></h2>
    <div class="contactsContainer">
      <a class="site" v-for="(site, index) in sites" :key="index"
         :href="'https://google.fr/maps/place/'+site.searchQuery"
         target="_blank" title="Nous trouver"
         rel="noopener noreferrer">
        <img class="img-site" :src="site.image" :alt="site.place">
        <div class="infos">
          <h5 class="place">{{ site.place }}</h5>
          <p class="adresse" v-html="site.adresse"></p>
        </div>
      </a>
    </div>
    <div class="newsletter">
      <div style="text-align: center;">
        <a target="_blank" :href="'mailto:'+mailToContact" class="link" title="Nous contacter par email">
          {{ mailToContact }}
        </a>
      </div>
      <div class="socials">
        <a class="socialItem" v-for="(social, index) in socials" :key="index" :href="social.link" target="_blank"
           rel="noopener, noreferrer" :name="social.name" :title="'Retrouvez-nous sur '+social.name">
          <span class="icon"></span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contacts',
  data() {
    return {
      script: {
        fr: {
          title: `nous<br><strong>contacter</strong>`
        },
        en: {
          title: `contact<br><strong>us</strong>`
        },
      },
      email: null,
      response: null,
      errorMessage: null,
      sites: [
        {
          place: 'Paris',
          adresse: '7 rue Vignon,<br>75008 Paris',
          image: require('@/assets/images/contacts/map_paris.png'),
          searchQuery: '7 rue Vignon, 75008 Paris'
        },
        {
          place: 'Lille',
          adresse: '134 rue Royale,<br>59800 Lille',
          image: require('@/assets/images/contacts/map_lille.png'),
          searchQuery: '134 rue Royale, 59800 Lille'
        },
        {
          place: 'Lyon',
          adresse: '32 Rue Gervais Bussière,<br>69100 Lyon',
          image: require('@/assets/images/contacts/map_lyon.png'),
          searchQuery: '32 Rue Gervais Bussière, 69100 Lyon'
        }
      ],
      mailToContact: 'contact@hobbynote.com',
      socials: [
        {
          name: 'Instagram',
          link: 'https://www.instagram.com/hobbynote',
        },
        {
          name: 'Linkedin',
          link: 'https://www.linkedin.com/company/hobbynote',
        },
        {
          name: 'Tiktok',
          link: 'https://www.tiktok.com/@hobbynote',
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contacts {
  min-height: initial !important;
  padding-top: 150px;
}

.contactsContainer {
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}

.site {
  flex: 1;
  position: relative;
  aspect-ratio: 2/1;
  border-radius: 100rem;
  overflow: hidden;
}

.img-site {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 300ms ease-in-out;
  filter: grayscale(1);
  border-radius: 100rem;
}

.infos {
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;
  text-align: center;
  color: #fff;
  isolation: isolate;
}

.infos::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, rgb(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  opacity: 1;
  z-index: -2;
}

.infos::after {
  content: '';
  position: absolute;
  inset: 0;
  background: #000000;
  opacity: 0.3;
  z-index: -1;
}

.place {
  font-size: 12vw;
  font-weight: 400;
  color: #fcd846;
}

.socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.socials a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  margin: 4px;
  width: 70px;
  height: 70px;
  font-size: 35px;
  line-height: 1;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: all 150ms ease-In-Out;
  transition: all 150ms ease-In-Out;
  text-decoration: none;
  color: #fff;
}

.socials a:hover {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.socials span {
  font-family: "icons";
}

.socials span::before {
  vertical-align: middle;
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

@media screen and (min-width: 1024px) {
  .contactsContainer {
    flex-direction: row;
  }

  .site {
    aspect-ratio: 329/544;
  }
  .place {
    font-size: 74px;
  }
}
@media screen and (max-width: 479px) {
  .contacts {
    padding-top: 100px;
  }
  }

@media (hover: hover) {
  .infos::before {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }

  .infos::after {
    opacity: 0.8;
    transition: opacity 300ms ease-in;
  }

  .place {
    color: #ffffff;
    transform: translateY(20px);
    transition: transform 300ms ease-in-out, color 300ms ease-in-out;
  }

  .adresse {
    transform: translateY(20px);
    opacity: 0;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  }
  .site:hover .img-site, .site:focus-within .img-site {
    transform: scale(1.05);
  }

  .site:hover .infos::before, .site:focus-within .infos::before {
    opacity: 1;
  }

  .site:hover .infos::after, .site:focus-within .infos::after {
    opacity: 0.3;
  }

  .site:hover .place, .site:focus-within .place {
    transform: translateY(0px);
    color: #fcd846;
  }

  .site:hover .adresse, .site:focus-within .adresse {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
