<template>
  <header>
    <div class="header">
      <transition name="top-fade">
        <div class="logo" :style="{height: 90 - (scrollPosition / 33.3334) + 'px'}" v-if="$parent.isLoad">
          <img src="../assets/images/logo_hbn.svg" alt="HbN" @click="$parent.scrollTo('#app');">
        </div>
      </transition>
      <transition name="top-fade">
        <div class="lang" v-if="$parent.isLoad" style="opacity: 1">
          <label class="switch">
            <input type="checkbox" v-model="langSelect" @change="$parent.currentLang(langSelect)">
            <span class="slider" :class="{main: !langSelect, second: langSelect}">
              <span class="lang">{{$parent.lang}}</span>
            </span>
          </label>
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    scrollPosition: Number
  },
  created() {
    if (this.$parent.lang == 'FR') {
      this.langSelect = false
    } else {
      this.langSelect = true
    }
  },
  data() {
    return {
      langSelect: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header {
  padding: 0 100px 0 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 75%);
  z-index: 10;
  min-width: 100%;
}
header::after {
  content: '';
  position: absolute;
  inset: 0;
  z-index: -1;
  background: rgba(41,42,43,1);
  background: linear-gradient(135deg, rgba(93,43,33,1) 0%, rgba(41,42,43,1) 100%);
}

header .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: calc(1230px + 105px*2);
}

header .logo {
  display: flex;
  align-items: center;
}

header .logo img {
  cursor: pointer;
  height: 50%;
  max-height: 69px;
  width: auto;
  margin: auto;
}
header .lang {
  display: none;
}
</style>
<style scoped>
@media screen and (min-width: 1450px) {
  header {
    padding: 0 15px 0 10px;
  }
  
  header .header {
    margin: auto;
    max-width: 1260px;
  }
}

@media screen and (max-width: 1259px) and (min-width: 1024px) {
  /* header { padding: 0 100px 0 80px; } */
}

@media screen and (max-width: 1023px) and (min-width: 480px){
  header {
    padding: 20px;
    padding-bottom: 15px;
    height: 100px;
  }

  header .logo {
    max-height: 65px;
    min-height: 65px;
  }
  .lang {
    margin-right: 70px;
  }
}

@media screen and (max-width: 479px){
  header {
    padding: 15px;
    padding-bottom: 10px;
    height: 70px;
  }

  header .logo {
    max-height: 45px;
    min-height: 45px;
  }

  .lang {
    margin-right: 70px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 3px solid #FFF;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  display: flex;
  align-items: center;
}

.slider.main {
  justify-content: flex-end;
}

.slider.sec {
  justify-content: flex-start;
}

.slider .lang {
  user-select: none;
  font-size: 13px;
  margin: 0 5px;
  padding-top: 4px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15.71px;
  width: 15.71px;
  left: 1.5px;
  bottom: 1.25px;
  background-color: #FFF;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

</style>