<template>
    <div class="menu">
      <nav class="main-menu">
          <ul class="main-menu-items">
                <transition v-for="(item, index) in navItems" :key="index" :name="'bottom-fade-0'+index">
                    <li class="main-menu-item" v-if="$parent.isLoad">
                        <a @click="$parent.scrollTo('#'+item.id); setPosition(item.id)" class="main-menu-item-link" :class="{active: current == item.id}" :title="item.name[$parent.lang.toLowerCase()]">{{item.name[$parent.lang.toLowerCase()]}}</a>
                    </li>
                </transition>
          </ul>
      </nav>
      <nav class="main-menu-mobile" :class="{active: toggleMenu}">
          <ul class="main-menu-items">
              <transition v-for="(item, index) in navItems" :key="index" :name="'right-fade-0'+index">
                <li class="main-menu-item" :class="{active: current == item.id}" v-if="toggleMenu">
                    <a class="main-menu-item-link" @click="$parent.scrollTo('#'+item.id); setPosition(item.id);" :title="item.name[$parent.lang.toLowerCase()]">{{item.name[$parent.lang.toLowerCase()]}}</a>
                </li>
              </transition>
          </ul>
      </nav>
      <div class="menu-toggle-button" @click="toggleMenu = !toggleMenu" :class="{active: toggleMenu}">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Navigation',
  methods: {
        changeSize() {
            let container = document.querySelector('.main-menu')
            let content = container.querySelector('.main-menu-items')
            let scale = 1
            if (content.clientWidth > container.clientWidth) {
                scale = (window.innerHeight / content.clientWidth) * 0.9
                content.style.transform = `scale3d(${scale}, ${scale}, 1)`
            }
            if (content.clientWidth <= container.clientWidth) {
                content.style.transform = 'scale3d(1, 1, 1)'
            }
            if (content.clientWidth == 0) { setTimeout(() => { this.changeSize() }, 50); }
        },
        isInViewport(element) {
            const top = element.offsetTop +  window.innerHeight / 3;
            const wTop = window.scrollY
            const wBottom = window.scrollY + window.innerHeight
            if (top > wTop && top < wBottom) {
                this.current = element.id
            }
        },
        getPosition() {
            const sections = document.querySelectorAll('section')
            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                if (!this.isClicked) {
                    this.isInViewport(section)
                }
            }
        },
        setPosition(position) {
            this.isClicked = true
            this.current = position
            this.toggleMenu = false
            setTimeout(() => { this.isClicked = false }, 1000)
        },
  },
  mounted() {
        this.changeSize()
        window.addEventListener('scroll', this.changeSize);
        window.addEventListener('resize', this.changeSize);
        this.getPosition()
        window.addEventListener('scroll', this.getPosition);
    },
    updated() {
        if (this.toggleMenu) {
            document.querySelector('body').style.overflow = "hidden"
        }
        if (!this.toggleMenu) {
            document.querySelector('body').style.overflow = "initial"
        }
  },
  destroy() {
        window.removeEventListener('scroll', this.changeSize);
        window.removeEventListener('resize', this.changeSize)
        window.removeEventListener('scroll', this.getPosition)
  },
  data() {
    return {
      current: 'histoire',
      isClicked: false,
      toggleMenu: false,
      navItems: [
          {
              name: {
                fr: 'Agence',
                en: 'Agency'
              },
              id: 'histoire'
          },
          {
              name: {
                fr: 'Clients',
                en: 'Clients'
              },
              id: 'clients'
          },
          {
              name: {
                fr: 'Contacts',
                en: 'Contacts'
              },
              id: 'contacts'
          },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
}

.menu a {
    cursor: pointer;
}

.menu .main-menu {
    display: block;
    position: fixed;
    width: 100vh;
    top: 0;
    right: 48%;
    transform: rotate(-90deg) translateY(655px);
    transform-origin: right top;
    counter-reset: index;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.menu .main-menu .main-menu-items {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: row-reverse;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
            justify-content: center;
    -ms-flex-align: end;
    -webkit-box-align: end;
            align-items: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    -webkit-transform-origin: 50% 50% 0px;
            transform-origin: 50% 50% 0px;
    list-style: none;
}

.menu .main-menu .main-menu-items .main-menu-item {
    position: relative;
    counter-increment: index;
}

.menu .main-menu .main-menu-items .main-menu-item .main-menu-item-link.active {
    color: #fff;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
}

.menu .main-menu .main-menu-items .main-menu-item .main-menu-item-link {
    display: block;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: gray;
    background-color: transparent;
    -webkit-transition: all 250ms ease-In-Out;
    transition: all 250ms ease-In-Out;
    letter-spacing: 0;
    text-decoration: none;
}

.menu .main-menu .main-menu-items .main-menu-item+.main-menu-item {
    margin-right: 60px;
}

.menu .main-menu .main-menu-items .main-menu-item+.main-menu-item::before {
    content: '•';
    position: absolute;
    right: -30px;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    font-size: 300%;
    line-height: .55;
    color: gray;
}

.menu .main-menu .main-menu-items .main-menu-item .main-menu-item-link.active::after {
    color: #fcd846;
}

.menu .main-menu .main-menu-items .main-menu-item .main-menu-item-link::after {
    content: "0" counter(index);
    position: absolute;
    font-size: 250%;
    font-weight: 400;
    left: 50%;
    top: 0%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    -webkit-transition: all 250ms ease-In-Out;
    transition: all 250ms ease-In-Out;
    z-index: -1;
    -webkit-clip-path: inset(0 0 30px 0);
    clip-path: inset(0 0 30px 0);
}

.menu .menu-toggle-button {
    display: none;
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 6000;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.4s linear;
    transition: -webkit-transform 0.4s linear;
    transition: transform 0.4s linear;
    transition: transform 0.4s linear, -webkit-transform 0.4s linear;
}

.menu .menu-toggle-button.active {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.menu .menu-toggle-button span {
    display: block;
    width: 35px;
    height: 6px;
    margin: 6px 0;
    border-radius: 6px;
    background-color: #fff;
    -webkit-transition: opacity 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    transition: opacity 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
    transition: transform 500ms ease-in-out, opacity 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
}

.menu .menu-toggle-button.active span {
    background-color: #fcd846;
}

.menu .menu-toggle-button.active span:first-child {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: right top 0px;
            transform-origin: right top 0px;
}

.menu .menu-toggle-button.active span:nth-child(2) {
    visibility: hidden;
    opacity: 0;
}

.menu .menu-toggle-button.active span:last-child {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: right bottom 0px;
            transform-origin: right bottom 0px;
}

.menu .main-menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    z-index: 5000;
    counter-reset: index;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in-out , -webkit-transform 0.5s ease-in 0.2s;
    transition: opacity 0.4s ease-in-out , -webkit-transform 0.5s ease-in 0.2s;
    transition: transform 0.5s ease-in 0.2s , opacity 0.4s ease-in-out;
    transition: transform 0.5s ease-in 0.2s , opacity 0.4s ease-in-out , -webkit-transform 0.5s ease-in 0.2s;
}

.menu .main-menu-mobile.active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
}

.menu .main-menu-mobile .main-menu-items {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    list-style: none;
}

.menu .main-menu-mobile .main-menu-items .main-menu-item {
    position: relative;
    counter-increment: index;
}

.menu .main-menu-mobile .main-menu-items .main-menu-item.active .main-menu-item-link {
    color: #fff;
    font-size: 8vmin;
    margin-top: 4vmin;
}

.menu .main-menu-mobile .main-menu-items .main-menu-item .main-menu-item-link {
    display: block;
    position: relative;
    font-size: 6vmin;
    font-weight: 500;
    text-transform: uppercase;
    color: gray;
    background-color: #000 !important;
}

.menu .main-menu-mobile .main-menu-items .main-menu-item.active {
    margin-top: 12vmin;
}

.menu .main-menu-mobile .main-menu-items .main-menu-item {
    margin-top: 10vmin;
}

.menu .main-menu-mobile .main-menu-items .main-menu-item.active .main-menu-item-link::after {
    color: #fcd846;
}

.menu .main-menu-mobile .main-menu-items .main-menu-item .main-menu-item-link::after {
    content: "0" counter(index);
    position: absolute;
    font-size: 250%;
    font-weight: 700;
    left: 50%;
    top: 0%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    z-index: -1;
}
</style>
<style scoped>
@media screen and (max-width: 1450px) and (min-width: 1024px) {
    .menu .main-menu {
        right: 35px;
        transform: rotate(-90deg) scale(0.9) translateX(-10%);
    }
    
}

@media screen and (max-width: 1023px) {
    .menu .menu-toggle-button {
        display: block;
    }
    .menu .main-menu {
        display: none;
        right: 10%;
    }
}

@media screen and (max-width: 479px){
    .menu .menu-toggle-button {
        top: 25px;
        right: 20px;
    }
    .menu .menu-toggle-button span {
        width: 25px;
        height: 4px;
        margin: 4px 0;
        border-radius: 4px;
    }
}
</style>
